import * as React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { findI18nNode } from '@/helpers/sanity.js';
import Seo from '@/components/Seo';
import Layout from '@/layouts/default';
import DivisionLogo from '@/components/DivisionLogo';
import Cover, { CoverProps } from '@/components/Cover';
import HeaderImage from '@/components/HeaderImage';
import BeneathHeader from '@/components/BeneathHeader';
import BlockContentContainer from '@/components/BlockContentContainer';
import BlockContent from '@/components/BlockContent';
import MetalTextScrollable from '@/components/MetalTextScrollable';
import StoryCarousel from '@/components/StoryCarousel';
import Container from '@/components/Container';

export interface DivisionTemplateProps {
  pageContext: {
    language: string;
    languageLinks: LanguageLinks;
  };
  data: {
    division: Pick<
      GatsbyTypes.SanityDivision,
      'id' | 'key' | 'title' | 'description' | 'i18n_lang' | '_rawBody'
    > & {
      readonly slug: Pick<GatsbyTypes.SanitySlug, 'current'>;
      readonly meta: Pick<GatsbyTypes.SanityMeta, 'title' | 'description'>;
      readonly mainMedia: CoverProps['media'];
    };
    allIntroVideos: GatsbyTypes.DivisionPageQuery['allIntroVideos'];
    stories: GatsbyTypes.DivisionPageQuery['stories'];
  };
}

const DivsionTemplate: React.FC<DivisionTemplateProps> = ({
  pageContext: { languageLinks },
  data: { division, allIntroVideos, stories },
}) => {
  const { language } = useI18next();

  const introVideo = (
    findI18nNode(
      allIntroVideos.edges,
      language,
    ) as GatsbyTypes.SanitySiteSettings
  )?.intro_video;

  return (
    <Layout languageLinks={languageLinks}>
      <Seo
        title={division!.meta!.title!}
        description={division!.meta!.description}
        image={division!.mainMedia}
      />
      <BeneathHeader>
        <header className="relative text-white mb-2xl-fluid">
          <HeaderImage position="absolute" className="inset-0 z-0">
            <Cover
              className="absolute inset-0"
              media={division.mainMedia}
              width={480}
              height={270}
            />
            <div className="absolute inset-x-0 bottom-0 z-40 top-1/2 bg-gradient-to-t from-blue-950 mix-blend-multiply opacity-90" />
          </HeaderImage>
          <Container className="relative z-10 flex flex-col items-center justify-center text-center min-h-screen-90 py-6xl-fluid">
            {division.key === 'duotec' && (
              <>
                <h1 className="sr-only">{division.title}</h1>
                <MetalTextScrollable
                  className="font-normal leading-none text-4xl-fluid max-w-prose-xs sm:text-4xl-fluid sm:leading-none"
                  top={0.25}
                  bottom={0.25}
                  lightModeSupport={false}
                >
                  {division.description}
                </MetalTextScrollable>
              </>
            )}
            {division.key !== 'duotec' && (
              <>
                <DivisionLogo
                  division={division.key ?? ''}
                  aria-hidden="true"
                  className="w-auto h-8 my-md-fluid lg:h-10 xl:h-12"
                />
                <h1 className="sr-only">{division.title}</h1>
                <MetalTextScrollable
                  className="font-normal leading-snug text-xl-fluid max-w-prose-sm py-xs"
                  top={0.25}
                  bottom={0.25}
                  lightModeSupport={false}
                >
                  {division.description}
                </MetalTextScrollable>
              </>
            )}
          </Container>
        </header>
      </BeneathHeader>
      <StoryCarousel
        stories={stories.edges}
        introVideo={division.key === 'duotec' ? introVideo : undefined}
        className="relative z-20 -mt-5xl-fluid mb-2xl-fluid"
      />
      <BlockContentContainer>
        <BlockContent blocks={division?._rawBody ?? []} />
      </BlockContentContainer>
    </Layout>
  );
};

export default DivsionTemplate;

export const query = graphql`
  query DivisionPage($language: String!, $id: String!) {
    allIntroVideos: allSanitySiteSettings {
      edges {
        node {
          i18n_lang
          intro_video {
            title
            preview {
              ...SanityCoverWithBlurredPlaceholderFields
            }
            video_mp4 {
              asset {
                url
              }
            }
            video_webm {
              asset {
                url
              }
            }
          }
        }
      }
    }
    division: sanityDivision(id: { eq: $id }) {
      id
      key
      title
      description
      i18n_lang
      slug {
        current
      }
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    stories: allSanityStory(
      filter: {
        i18n_lang: { eq: $language }
        hasParentItem: { eq: false }
        division: { id: { eq: $id } }
      }
      sort: { fields: priority, order: DESC }
    ) {
      edges {
        node {
          ...StoryCardFields
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
  }
`;
